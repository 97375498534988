import React from "react"
import { gql } from "@apollo/client"
import {
  makeStyles,
  Card,
  CardHeader,
  Avatar,
  Typography,
  Box,
  CardActions,
  Button,
} from "@material-ui/core"
import { red } from "@material-ui/core/colors"
import { Rating } from "@material-ui/lab"
import { DateTime } from "luxon"
import { ExternalReviewCardReviewFragment } from "../generated/graphql"
import { ExternalReviewSource } from "../Store"
import { getReviewAffiliateUrl } from "../utils"

export const externalReviewCardReviewFragment = gql`
  fragment ExternalReviewCardReview on external_reviews {
    id
    title
    body
    date
    rating
    url
    source
  }
`

interface ExternalReviewCardProps {
  review: ExternalReviewCardReviewFragment
}

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: red[500],
  },
  maxLines: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 4,
    "-webkit-box-orient": "vertical",
  },
}))

const SourceToNameMap: Record<ExternalReviewSource, string> = {
  [ExternalReviewSource.MasterOfMalt]: "Master of Malt",
  [ExternalReviewSource.TheWhiskyExchange]: "The Whisky Exchange",
}

const UrlDefaultMap: Record<ExternalReviewSource, string> = {
  [ExternalReviewSource.MasterOfMalt]: "https://www.masterofmalt.com/",
  [ExternalReviewSource.TheWhiskyExchange]:
    "https://www.thewhiskyexchange.com/",
}

export const ExternalReviewCard: React.FC<ExternalReviewCardProps> = ({
  review,
}) => {
  const classes = useStyles()

  const { source, body, rating, title = "Anonymous" } = review

  const date = DateTime.fromISO(review.date).toLocaleString(DateTime.DATE_FULL)
  const reviewSource = source as ExternalReviewSource
  const url = review.url || UrlDefaultMap[reviewSource]
  const ratingNormalized = (rating ?? 0) / 2

  return (
    <Card key={review.id}>
      <CardHeader
        avatar={
          <Avatar aria-label="review" className={classes.avatar}>
            {title?.[0].toLocaleUpperCase()}
          </Avatar>
        }
        title={title}
        subheader={date}
      />

      <Box pb={3} paddingX={2}>
        <Rating
          name="rating-input"
          value={ratingNormalized}
          precision={0.1}
          size="small"
          readOnly
        />
        <Box mt={1}>
          <Typography
            className={classes.maxLines}
            style={{ whiteSpace: "pre-wrap" }}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {body}
          </Typography>
        </Box>
      </Box>
      <CardActions>
        <Button
          href={getReviewAffiliateUrl(url, reviewSource)}
          color="primary"
        >{`Read more at ${SourceToNameMap[reviewSource] ?? source}`}</Button>
      </CardActions>
    </Card>
  )
}
