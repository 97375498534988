import React from "react"
import { Typography, Box, Tooltip as MuiTooltip } from "@material-ui/core"
import { Rating } from "@material-ui/lab"
import HelpIcon from "@material-ui/icons/Help"
import { round } from "lodash"

export const RatingDisplay: React.FC<{
  avgRating: number
  nrRatings: number
}> = ({ avgRating, nrRatings }) => {
  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="h3" component="p">
          {round(avgRating, 1)}
        </Typography>
        <Box ml={2}>
          <Rating
            name="average-rating"
            value={avgRating}
            precision={0.1}
            size="large"
            readOnly
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="body1">
          {`Based on ${nrRatings} reviews`}{" "}
        </Typography>
        <MuiTooltip title="Combined reviews from other sources and members. The score is updated daily.">
          <HelpIcon style={{ fontSize: 18, marginLeft: 8 }} />
        </MuiTooltip>
      </Box>
    </>
  )
}
