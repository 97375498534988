import { Typography, Box, Button, Tabs, Tab } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import { grey } from "@material-ui/core/colors"
import { take } from "lodash"
import React from "react"
import {
  ExternalReviewCardReviewFragment,
  ProductReviewsQuery,
  ProductReviewsQueryVariables,
  ReviewCardReviewFragment,
  UserProductReviewQuery,
  UserProductReviewQueryVariables,
} from "src/generated/graphql"
import { ReviewDialog } from "../../components/ReviewDialog"
import { gql, useQuery } from "@apollo/client"
import {
  ReviewCard,
  reviewCardReviewFragment,
} from "../../components/ReviewCard"
import {
  withFirebase,
  WithFirebaseWrappedProps,
} from "../../service/firebase/withFirebase"
import { useAuthState } from "react-firebase-hooks/auth"
import { RatingDisplay } from "./RatingDisplay"
import { ProductPageNodeData } from "../ProductPage"
import { ExternalReviewCard } from "../../components/ExternalReviewCard"
import { ProductCategory } from "../../measurables"

const TABS = [
  {
    id: "members",
    title: "Members",
  },
  {
    id: "other",
    title: "Other sources",
  },
] as const

type TabId = typeof TABS[number]["id"]

const getReviewsQuery = gql`
  query ProductReviews($productId: uuid!) {
    reviews(
      where: { product_id: { _eq: $productId } }
      order_by: { created_at: desc }
      limit: 100
    ) {
      id
      ...ReviewCardReview
    }
  }
  ${reviewCardReviewFragment}
`
interface ReviewSectionProps {
  product: ProductPageNodeData
}
export const ReviewSection: React.FC<ReviewSectionProps> = ({
  product: { productId, category, reviewsData, externalReviews },
}) => {
  const [currentTab, setCurrentTab] = React.useState<TabId>("members")
  const { avgRating, nrRatings } = reviewsData
  const { data, loading, error, refetch } = useQuery<
    ProductReviewsQuery,
    ProductReviewsQueryVariables
  >(getReviewsQuery, {
    variables: { productId },
  })

  const onTabSelected = (_event: React.ChangeEvent<{}>, value: TabId) => {
    setCurrentTab(value)
  }

  const onReviewSubmitted = () => refetch()

  return (
    <>
      <Box mb={1}>
        <Typography variant="h4" component="h3">
          Ratings and Reviews
        </Typography>
      </Box>
      <Box>
        <RatingDisplay nrRatings={nrRatings} avgRating={avgRating} />
      </Box>
      <Box marginY={2}>
        <CreateOrChangeReviewComponent
          onReviewSubmitted={onReviewSubmitted}
          productId={productId}
          category={category as ProductCategory}
        ></CreateOrChangeReviewComponent>
      </Box>
      <Box>
        <Tabs
          value={currentTab}
          onChange={onTabSelected}
          indicatorColor="primary"
        >
          {TABS.map(tab => (
            <Tab key={tab.id} value={tab.id} label={tab.title} />
          ))}
        </Tabs>
      </Box>
      {currentTab === "members" ? (
        <ReviewList
          data={data?.reviews}
          ReviewComponent={ReviewCard}
          emptyMessage="It's pretty empty here, you should definitely write a review..."
        ></ReviewList>
      ) : null}

      {currentTab === "other" ? (
        <ReviewList
          data={externalReviews}
          ReviewComponent={ExternalReviewCard}
          emptyMessage="We haven't found any reviews for this elsewhere"
        ></ReviewList>
      ) : null}
    </>
  )
}

interface ReviewListProps<
  T = ReviewCardReviewFragment | ExternalReviewCardReviewFragment
> {
  data?: T[]
  ReviewComponent: React.ComponentType<{ review: T }>
  emptyMessage: string
}

const ReviewList: React.FC<ReviewListProps> = ({
  data,
  ReviewComponent,
  emptyMessage,
}) => {
  const [visibleLimit, setVisibleLimit] = React.useState(5)
  const onShowMore = () => setVisibleLimit(limit => limit + 5)

  const visibleData = take(data, visibleLimit)

  return (
    <>
      {visibleData.length === 0 && (
        <Box mt={2} p={3} bgcolor={grey[100]} borderRadius={8}>
          <Typography>{emptyMessage}</Typography>
        </Box>
      )}
      <Box mt={2} display="flex" flexDirection="column">
        {visibleData.map(review => (
          <Box key={review.id} mb={1}>
            <ReviewComponent review={review} />
          </Box>
        ))}
      </Box>
      <Box mt={1} display="flex" justifyContent="center">
        {data && visibleLimit < data.length ? (
          <Button startIcon={<AddIcon />} onClick={onShowMore}>
            Show more
          </Button>
        ) : null}
      </Box>
    </>
  )
}

const getUserReviewQuery = gql`
  query UserProductReview($productId: uuid!, $userId: String) {
    userReviews: reviews(
      where: { user_id: { _eq: $userId }, product_id: { _eq: $productId } }
      limit: 1
    ) {
      id
      ...ReviewCardReview
    }
  }
  ${reviewCardReviewFragment}
`

interface CreateOrChangeReviewProps extends WithFirebaseWrappedProps {
  productId: string
  category: ProductCategory
  onReviewSubmitted: () => void
}
const CreateOrChangeReviewComponent = withFirebase(
  ({
    productId,
    category,
    onReviewSubmitted,
    firebaseApp,
  }: CreateOrChangeReviewProps) => {
    const [user] = useAuthState(firebaseApp.auth())
    const { data, loading, error, refetch } = useQuery<
      UserProductReviewQuery,
      UserProductReviewQueryVariables
    >(getUserReviewQuery, {
      variables: { productId, userId: user?.uid },
      skip: !user,
    })

    const userReview = data?.userReviews[0]
    const onReviewUpdated = () => {
      onReviewSubmitted()
      refetch()
    }

    if (!userReview) {
      return (
        <ReviewDialog
          onReviewSubmitted={onReviewUpdated}
          productId={productId}
          category={category}
        ></ReviewDialog>
      )
    }

    return (
      <>
        <Box marginY={1}>
          <Typography variant="h5">Your review</Typography>
        </Box>
        <Box p={3} bgcolor={grey[100]} borderRadius={8}>
          <ReviewCard
            review={userReview}
            canEdit
            onReviewUpdated={onReviewUpdated}
          />
        </Box>
      </>
    )
  }
)
