import { gql } from "@apollo/client"
import {
  makeStyles,
  Card,
  CardHeader,
  Avatar,
  Typography,
  Box,
  CardActions,
  Button,
  Slider,
  Divider,
  withStyles,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import { red } from "@material-ui/core/colors"
import { Rating } from "@material-ui/lab"
import { DateTime } from "luxon"
import React, { useEffect } from "react"
import { ReviewCardReviewFragment } from "src/generated/graphql"
import { ReviewDialog } from "./ReviewDialog"
import {
  getMeasurablesForCategory,
  MEASURABLE_LABELS,
  Measureable,
  ProductCategory,
} from "../measurables"

export const reviewCardReviewFragment = gql`
  fragment ReviewCardReview on reviews {
    id
    rating
    description
    created_at
    measurables
    product {
      id
      category
    }
    author {
      name
    }
  }
`

interface ReviewCardProps {
  review: ReviewCardReviewFragment
  canEdit?: boolean
  onReviewUpdated?: () => void
}

const ThickerSlider = withStyles(theme => ({
  disabled: {},
  root: {
    height: 6,
    color: theme.palette.primary.main,
    cursor: "default",
    "pointer-events": "none",
  },
  thumb: {
    height: 14,
    width: 14,
    border: "2px solid currentColor",
    marginTop: -5,
    marginLeft: -4,
  },
  track: {
    height: 4,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
}))(Slider)

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: red[500],
  },
  maxLines: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": (props: any) => (props.showAll ? null : 5),
    "-webkit-box-orient": "vertical",
  },
}))

export const ReviewCard: React.FC<ReviewCardProps> = ({
  review,
  canEdit,
  onReviewUpdated,
}) => {
  const [showAll, setShowAll] = React.useState(false)
  const [isClamped, setIsClamped] = React.useState(false)
  const classes = useStyles({ showAll })
  const descriptionContainerRef =
    React.useRef<HTMLParagraphElement | null>(null)
  const { description, rating, product } = review
  const name = review.author?.name ?? "Anonymous"
  const date = DateTime.fromISO(review.created_at).toLocaleString(
    DateTime.DATE_FULL
  )
  const category = product.category as ProductCategory
  const measurables = review.measurables as Record<Measureable, number>
  const allMeasurables = getMeasurablesForCategory(category)

  useEffect(() => {
    setIsClamped(
      !!descriptionContainerRef.current &&
        descriptionContainerRef.current?.scrollHeight >
          descriptionContainerRef.current?.clientHeight
    )
  }, [descriptionContainerRef.current, description])

  const showFullDescription = () => {
    setShowAll(visible => !visible)
  }

  const ExpandIcon = showAll ? <ExpandLessIcon /> : <ExpandMoreIcon />

  return (
    <Card key={review.id}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {name[0].toLocaleUpperCase()}
          </Avatar>
        }
        title={name}
        subheader={date}
      />

      <Box pb={canEdit ? 0 : 3} paddingX={2}>
        <Rating
          name="rating-input"
          value={rating}
          precision={0.5}
          size="small"
          readOnly
        />
        {measurables && Object.keys(measurables).length > 0 && (
          <>
            <Box mt={3}>
              {allMeasurables.map((measurable: Measureable) => {
                const labels = MEASURABLE_LABELS[measurable]
                if (measurables[measurable] === undefined) return null

                return (
                  <Box key={measurable}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="caption">{labels[0]}</Typography>
                      <Typography variant="caption">{labels[1]}</Typography>
                    </Box>
                    <ThickerSlider
                      min={0}
                      max={100}
                      track={false}
                      value={measurables[measurable]}
                      aria-labelledby={measurable}
                    />
                  </Box>
                )
              })}
            </Box>
            <Box marginY={2}>
              <Divider />
            </Box>
          </>
        )}
        <Box mt={1}>
          <Typography
            ref={descriptionContainerRef}
            className={classes.maxLines}
            style={{ whiteSpace: "pre-wrap" }}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {description}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          {isClamped && (
            <Button startIcon={ExpandIcon} onClick={showFullDescription}>{`${
              showAll ? "Hide" : "Show"
            } full review`}</Button>
          )}
        </Box>
      </Box>
      {canEdit && (
        <CardActions>
          <ReviewDialog
            onReviewSubmitted={onReviewUpdated}
            productId={product.id}
            category={category}
            openButton={<Button size="small">Update</Button>}
            initialRating={rating}
            initalDescription={description ?? undefined}
            initialMeasurables={measurables}
          ></ReviewDialog>
        </CardActions>
      )}
    </Card>
  )
}
