import React, { useEffect } from "react"
import type firebaseType from "firebase/app"
import { isNotSSR } from "../../utils"
import { getFirebaseApp, firebaseApp } from "./firebase"

export interface WithFirebaseWrappedProps {
  firebaseApp: firebaseType.app.App
}

export interface WithFirebaseOptions {}

export const withFirebase = (
  WrappedComponent: React.ComponentType<WithFirebaseWrappedProps>,
  _opts?: WithFirebaseOptions
): React.FC<any> => props => {
  const [app, setApp] = React.useState<firebaseType.app.App | undefined>(
    firebaseApp
  )

  useEffect(() => {
    if (isNotSSR() && !app) {
      getFirebaseApp().then(firebase => setApp(firebase))
    }
  }, [app])

  if (app) return <WrappedComponent firebaseApp={app} {...props} />

  return null
}
