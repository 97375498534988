import { makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import React, { FC } from "react"
import { Store } from "../Store"
import MASTER_OF_MALT_LOGO from "../images/store-logos/MasterOfMaltLogo.svg"
import THE_WHISKY_SHOP_LOGO from "../images/store-logos/TheWhiskyShopLogo.svg"
import HOUSE_OF_MALT_LOGO from "../images/store-logos/HouseOfMaltLogo.svg"
import LOCH_FYNE_WHISKIES from "../images/store-logos/LochFyneWhiskies.png"
import DRINKS_AND_CO from "../images/store-logos/DrinksAndCo.svg"
import THE_WHISKY_EXCHANGE from "../images/store-logos/theWhiskyExchange.svg"

type StoreMap = { [store in Store]: string }

const StoreLogos: StoreMap = {
  [Store.MasterOfMalt]: MASTER_OF_MALT_LOGO,
  [Store.TheWhiskyShop]: THE_WHISKY_SHOP_LOGO,
  [Store.HouseOfMalt]: HOUSE_OF_MALT_LOGO,
  [Store.LochFyneWhiskies]: LOCH_FYNE_WHISKIES,
  [Store.DrinksAndCo]: DRINKS_AND_CO,
  [Store.TheWhiskyExchange]: THE_WHISKY_EXCHANGE,
  [Store.Amazon]:
    "https://media.corporate-ir.net/media_files/IROL/17/176060/Oct18/Amazon%20logo.PNG",
}

const StoreName: StoreMap = {
  [Store.MasterOfMalt]: "Master of Malt",
  [Store.TheWhiskyShop]: "The Whisky Shop",
  [Store.HouseOfMalt]: "House of Malt",
  [Store.Amazon]: "Amazon",
  [Store.LochFyneWhiskies]: "Loch Fyne Whiskies",
  [Store.DrinksAndCo]: "Drinks&Co",
  [Store.TheWhiskyExchange]: "The Whisky Exchange",
}

export interface StoreLogoProps {
  store: Store
  className?: string
}

const useStyles = makeStyles(() => ({
  img: {
    width: "100%",
    height: "100%",
    backgroundImage: (props: any) => `url(${props.imgUrl})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
}))

export const StoreLogo: FC<StoreLogoProps> = ({ store, className }) => {
  const imgUrl = StoreLogos[store]
  if (!imgUrl)
    return (
      <Typography className={className} variant="subtitle2">
        {StoreName[store] || "unknown store"}
      </Typography>
    )

  const classes = useStyles({ imgUrl })

  return (
    <div
      className={clsx(classes.img, className)}
      role="img"
      aria-label={`${StoreName[store] || "unknown store"} logo`}
    ></div>
  )
}
