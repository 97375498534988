import {
  makeStyles,
  Paper,
  Typography,
  Box,
  Grid,
  Divider,
  Button,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core"

import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts"
import SwiperCore, { Navigation, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

import { round, reduce, sortBy, maxBy, take, groupBy, uniq } from "lodash"
import { EventType, trackEvent } from "../service/track"

import { PageProps } from "gatsby"

import React, { FC } from "react"
import { Footer, Header, PageMaxWidthContainer } from "../components/layout"
import { getProductInfo_hasura_products_by_pk_listings } from "./__generated__/getProductInfo"
import SEO from "../components/seo"
import { useHelperStyles } from "../styles/helpers"
import { ListingItemCard } from "../components/ListingItemCard"
import { DesktopProductItemCard } from "../components/ProductItemCard"
import { logger } from "../logger"

SwiperCore.use([Navigation, A11y])

import STOCK_IMAGE_PLACEHOLDER from "../images/whisky-stock.jpg"
import { ProductItemCardFragment } from "src/components/ProductItemCard/__generated__/ProductItemCardFragment"
import {
  ExternalReviewCardReviewFragment,
  ListingItemCardFragmentFragment,
  ProductItemCardFragmentFragment,
} from "src/generated/graphql"
import clsx from "clsx"
import { DateTime } from "luxon"
// import { AuthDialog } from "../components/AuthDialog"
import { RatingDisplay } from "./ProductPage/RatingDisplay"
import { ReviewSection } from "./ProductPage/ReviewSection"

export interface PriceHistory {
  date: string
  price: number | null
}
export interface ProductPageNodeData {
  productId: string
  title: string
  category: string
  description: string
  imgUrl?: string
  distillery: string
  priceHistory: PriceHistory[]
  listings: ListingItemCardFragmentFragment[]
  groupedListings: Array<{
    group: ListingGroup
    listings: ListingItemCardFragmentFragment[]
  }>
  tags: Array<{
    id: string
    type: string
    value: string
  }>
  similarProducts?: ProductItemCardFragmentFragment[]
  reviewsData: {
    avgRating: number
    nrRatings: number
  }
  externalReviews: ExternalReviewCardReviewFragment[]
  productJsonLd: any
}

const useNewStyles = makeStyles(theme => ({
  imgContainer: {
    padding: 8,
    borderRadius: 16,
  },
  img: {
    height: 400,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundImage: (props: any) => `url(${props.imgUrl})`,
  },
  tag: {
    minWidth: 60,
  },
  otherListingSlide: {
    paddingBottom: 8,
    /* Center slide text vertically */
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "auto",
  },
  otherListingsContainer: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },
  topContainer: {
    paddingBottom: theme.spacing(1),
  },
  tabContainer: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    marginBottom: theme.spacing(1),
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.appBar,
  },
  scrollMargin: {
    scrollMarginTop: "56px",
  },
}))

interface ProductPageContext {
  pageData: ProductPageNodeData
}

const TABS = [
  {
    id: "price",
    title: "Price",
  },
  {
    id: "reviews",
    title: "Reviews",
  },
  {
    id: "priceHistory",
    title: "Price History",
  },
  {
    id: "information",
    title: "Information",
  },
] as const

type TabId = typeof TABS[number]["id"]

const getYPosition = (el?: HTMLElement | null) =>
  el?.getBoundingClientRect().top ?? Number.POSITIVE_INFINITY

export default function ProductPage({
  pageContext,
}: PageProps<any, ProductPageContext>) {
  const product = pageContext.pageData
  const pricesRef = React.useRef<HTMLDivElement>(null)
  const priceHistoryRef = React.useRef<HTMLElement>(null)
  const informationRef = React.useRef<HTMLElement>(null)
  const reviewsRef = React.useRef<HTMLElement>(null)
  const [currentTab, setCurrentTab] = React.useState<TabId>("price")

  React.useEffect(() => {
    // Stuff to only run on page
    logger.dev("product", { product })
    trackEvent(EventType.VIEWED_PRODUCT, {
      id: product.productId,
      title: product.title,
      category: product.category,
    })
  }, [])

  useScrollPosition(() => {
    const MARGIN = 60
    const priceHistoryYOffset = getYPosition(priceHistoryRef.current) - MARGIN
    const pricesYOffset = getYPosition(pricesRef.current) - MARGIN
    const informationYOffset = getYPosition(informationRef.current) - MARGIN
    const reviewsYOffset = getYPosition(reviewsRef.current) - MARGIN

    if (priceHistoryYOffset <= 0) {
      setCurrentTab("priceHistory")
    } else if (reviewsYOffset <= 0) {
      setCurrentTab("reviews")
    } else if (pricesYOffset <= 0) {
      setCurrentTab("price")
    } else if (informationYOffset <= 0) {
      setCurrentTab("information")
    }
  }, [priceHistoryRef, pricesRef])

  if (!product) return "Something went wrong..."

  const title = product.title
  const distillery = product.distillery
  const imgUrl = product.imgUrl ?? STOCK_IMAGE_PLACEHOLDER
  const description = product.description
  const priceHistory = product.priceHistory
  const { avgRating, nrRatings } = product.reviewsData

  const similarProducts = product.similarProducts?.filter(
    (simProduct: any) => simProduct.id !== product.productId
  )

  const groupedListings = reduce(
    product.groupedListings,
    (prev, { group, listings }) => ({ ...prev, [group]: listings }),
    {} as Record<ListingGroup, getProductInfo_hasura_products_by_pk_listings[]>
  )

  const classes = useNewStyles({ imgUrl })
  const utilClasses = useHelperStyles()

  const onTabSelected = (_event: React.ChangeEvent<{}>, value: TabId) => {
    // if (value === )
    let el: HTMLElement | null = null
    if (value === "price") {
      el = pricesRef.current
    } else if (value === "reviews") {
      el = reviewsRef.current
    } else if (value === "priceHistory") {
      el = priceHistoryRef.current
    } else if (value === "information") {
      el = informationRef.current
    }

    el?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <>
      <Paper className={classes.topContainer} elevation={0}>
        <Header />
        <SEO
          title={title}
          description={description}
          jsonLd={product.productJsonLd}
        />
        <PageMaxWidthContainer>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.imgContainer} variant="outlined">
                  <div className={classes.img} />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" height="100%">
                  <Box>
                    <Typography
                      className={utilClasses.maxTwoLines}
                      variant="h4"
                      component="h1"
                    >
                      {product?.title}
                    </Typography>
                    <Typography variant="subtitle2" component="p">
                      {distillery}
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <RatingDisplay
                      avgRating={avgRating}
                      nrRatings={nrRatings}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </PageMaxWidthContainer>
      </Paper>
      <Paper className={classes.tabContainer} elevation={0}>
        <PageMaxWidthContainer>
          <Tabs
            value={currentTab}
            onChange={onTabSelected}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {TABS.map(tab => (
              <Tab key={tab.id} value={tab.id} label={tab.title} />
            ))}
          </Tabs>
        </PageMaxWidthContainer>
      </Paper>
      <PageMaxWidthContainer>
        <Box mt={6} mb={6}>
          <Divider />
        </Box>

        <section className={classes.scrollMargin} ref={pricesRef}>
          <ListingGroupComponent
            group={ListingGroup.SAMPLE}
            listings={groupedListings[ListingGroup.SAMPLE]}
          />

          <ListingGroupComponent
            group={ListingGroup.SMALL}
            listings={groupedListings[ListingGroup.SMALL]}
          />

          <ListingGroupComponent
            group={ListingGroup.REGULAR}
            listings={groupedListings[ListingGroup.REGULAR]}
          />

          <ListingGroupComponent
            group={ListingGroup.BIG}
            listings={groupedListings[ListingGroup.BIG]}
          />

          <ListingGroupComponent
            group={ListingGroup.UNAVAILABLE}
            listings={groupedListings[ListingGroup.UNAVAILABLE]}
          />
        </section>

        <Box mt={6} mb={6}>
          <Divider />
        </Box>
      </PageMaxWidthContainer>
      {similarProducts?.length ? (
        <Paper className={classes.otherListingsContainer} elevation={0}>
          <PageMaxWidthContainer>
            <Box pt={2} pb={2}>
              <Typography variant="h4" component="h3">
                Similar products
              </Typography>
              <Box mt={2}>
                <Swiper navigation slidesPerView={"auto"} spaceBetween={16}>
                  {similarProducts?.map(simProduct => (
                    <SwiperSlide
                      key={simProduct.id}
                      className={classes.otherListingSlide}
                      style={{ width: "auto" }}
                    >
                      <DesktopProductItemCard
                        key={simProduct.id}
                        product={(simProduct as any) as ProductItemCardFragment}
                      ></DesktopProductItemCard>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            </Box>
          </PageMaxWidthContainer>
        </Paper>
      ) : null}

      <Paper
        ref={reviewsRef}
        className={clsx(classes.otherListingsContainer, classes.scrollMargin)}
        elevation={0}
        component="section"
      >
        <PageMaxWidthContainer>
          <Box pt={3} pb={3}>
            <ReviewSection product={product}></ReviewSection>
          </Box>
        </PageMaxWidthContainer>
      </Paper>

      <Paper
        ref={priceHistoryRef}
        className={clsx(classes.otherListingsContainer, classes.scrollMargin)}
        elevation={0}
        component="section"
      >
        <PageMaxWidthContainer>
          <Box pt={3} pb={3}>
            <PriceChart priceHistory={priceHistory || []}></PriceChart>
          </Box>
        </PageMaxWidthContainer>
      </Paper>

      <Paper
        ref={informationRef}
        className={clsx(classes.otherListingsContainer, classes.scrollMargin)}
        elevation={0}
        component="section"
      >
        <PageMaxWidthContainer>
          <Box pt={3} pb={3}>
            <TagInformationTable tags={product.tags} />
          </Box>
        </PageMaxWidthContainer>
      </Paper>
      <Footer />
    </>
  )
}

const TAG_ROWS = [
  { type: "country", title: "Country" },
  { type: "region", title: "Region" },
  { type: "distillery", title: "Distillery / Producer" },
  { type: "style", title: "Style" },
  { type: "age", title: "Age" },
  { type: "abv", title: "Abv" },
  { type: "feature", title: "Features" },
] as const

export const formatTagValues = (
  type: typeof TAG_ROWS[number]["type"],
  tags?: ProductPageNodeData["tags"]
) => {
  const values = uniq(tags?.map(tag => tag.value))

  if (values.length === 0) return

  if (type === "abv") {
    return values.map(v => `${v}%`).join(", ")
  } else if (type === "age") {
    return values.map(v => `${v} Years Old`).join(", ")
  }

  return values.join(", ")
}

const TagInformationTable: React.FC<{
  tags: ProductPageNodeData["tags"]
}> = ({ tags }) => {
  const groupedTags = groupBy(tags, "type")

  return (
    <>
      <Box mb={1}>
        <Typography id="info-table-title" variant="h4" component="h3">
          Product Information
        </Typography>
      </Box>
      {tags.length === 0 ? (
        <Box>
          <Typography id="info-table-title" variant="subtitle1">
            No information available
          </Typography>
        </Box>
      ) : null}
      <TableContainer>
        <Table aria-labelledby="info-table-title">
          <TableBody>
            {TAG_ROWS.map(({ type, title }) => {
              return (
                groupedTags[type] && (
                  <TableRow key={type}>
                    <TableCell component="th" scope="row">
                      <Box component="span" fontWeight="bold">
                        {title}
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      {formatTagValues(type, groupedTags[type])}
                    </TableCell>
                  </TableRow>
                )
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

interface PriceChartProps {
  priceHistory: { date: string; price: number | null }[]
}

const PriceChart: FC<PriceChartProps> = ({ priceHistory }) => (
  <>
    <Box mb={3}>
      <Typography variant="h4" component="h3">
        Price History
      </Typography>
    </Box>
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart data={priceHistory}>
        <Area
          type="monotone"
          dataKey="price"
          stroke="#3d5afe"
          fill="#3d5afe"
          fillOpacity={0.4}
          isAnimationActive={false}
        />
        <CartesianGrid
          vertical={false}
          stroke="#000"
          opacity={0.18}
          strokeDasharray="5 5"
        />
        <XAxis
          dataKey="date"
          tick={true}
          minTickGap={16}
          tickFormatter={value => DateTime.fromISO(value).toFormat("dd. LLL")}
        />
        <YAxis
          mirror
          tickFormatter={value => `£${value.toFixed(1)}`}
          domain={[0, (maxBy(priceHistory, "price")?.price ?? 100) * 1.3]}
          minTickGap={8}
        />
        <Tooltip
          labelFormatter={value => DateTime.fromISO(value).toFormat("dd. LLL")}
          formatter={(value: number | string) =>
            `£${typeof value === "number" ? round(value, 2) : value}/70cl`
          }
        />
      </AreaChart>
    </ResponsiveContainer>
  </>
)

enum ListingGroup {
  SAMPLE = "Samples",
  SMALL = "Small",
  REGULAR = "Regular",
  BIG = "Big",
  UNAVAILABLE = "Unavailable",
}

const LISTING_GROUP_DESCRIPTION: { [group in ListingGroup]: string } = {
  [ListingGroup.SAMPLE]:
    "Samples offer spirits enthusiasts the opportunity to sample releases at a fraction of the cost of purchasing an entire bottle.",
  [ListingGroup.SMALL]:
    "Ranging between 20cl to 35cl, these listings offer the same whisky in a smaller package, making it easier to transport and usually less of an upfront cost.",
  [ListingGroup.REGULAR]:
    "Regular sized bottles are usually in the 50cl to 70cl range.",
  [ListingGroup.BIG]:
    "Big buys usually offer the best price per cl but are also more expensive upfront. If you know what you like buying big could be the way to go.",
  [ListingGroup.UNAVAILABLE]:
    "These listings have not been available for quite some time, which means they might not be produced anymore or it's hard to get a hold of.",
}

interface ListingGroupComponentProps {
  group: ListingGroup
  listings?: getProductInfo_hasura_products_by_pk_listings[]
}

const ListingGroupComponent: React.FC<ListingGroupComponentProps> = ({
  group,
  listings,
}) => {
  const [seeAll, setSeeAll] = React.useState(false)
  if (!listings || listings.length === 0) return null

  const sortedListings = sortBy(listings, "price")
  const first = take(sortedListings, 5)
  const activeList = seeAll ? sortedListings : first
  const onClickSeeAll = () => setSeeAll(!seeAll)
  const hasMore = first.length < sortedListings.length

  return (
    <Box mt={2} mb={6}>
      <Box>
        <Typography variant="h4" component="h3">
          {group}
        </Typography>
        <Box mt={1} mb={1}>
          <Typography variant="body2">
            {LISTING_GROUP_DESCRIPTION[group]}
          </Typography>
        </Box>
      </Box>
      <Divider></Divider>
      <Box mt={2}>
        <Grid container spacing={1}>
          {activeList.map(listing => (
            <Grid item xs={12} key={listing.id}>
              <ListingItemCard listing={listing} />
            </Grid>
          ))}
        </Grid>
      </Box>
      {hasMore && (
        <Box mt={2} display="flex" justifyContent="center">
          <Button onClick={onClickSeeAll}>
            Show {seeAll ? "Less" : "More"}
          </Button>
        </Box>
      )}
    </Box>
  )
}
