export type ProductCategory = "whisky"

export type Measureable = "smokiness" | "richness" | "sweetness"

export const MEASURABLE_LABELS: Record<Measureable, readonly [string, string]> =
  {
    smokiness: ["Not Smoky", "Smoky"],
    richness: ["Delicate", "Rich"],
    sweetness: ["Dry", "Sweet"],
  } as const

const MEASURABLES_FOR_CATEGORIES: Record<ProductCategory, Measureable[]> = {
  whisky: ["smokiness", "richness", "sweetness"],
}

export const getMeasurablesForCategory = (
  category: ProductCategory
): Measureable[] => {
  return MEASURABLES_FOR_CATEGORIES[category] || []
}
