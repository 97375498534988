import React from "react"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Box,
} from "@material-ui/core"
import { getFirebaseUi } from "../service/firebaseUi"
import {
  AUTH_PROVIDERS,
  firebaseApp,
  FirebaseUser,
} from "../service/firebase/firebase"
import { getAuthToken } from "../service/firebase/auth"

export interface AuthDialogProps {
  open: boolean

  onAuthAbort: () => void
  onAuthComplete: (user: FirebaseUser) => void
}

export const AuthDialog: React.FC<AuthDialogProps> = ({
  open,
  onAuthComplete,
  onAuthAbort,
}) => {
  const [loading, setLoading] = React.useState(false)
  const [isNewUser, setIsNewUser] = React.useState(false)

  const handleAuthComplete = async (authResult: { user: FirebaseUser }) => {
    setLoading(true)
    // Make sure the authtoken get refreshed
    await getAuthToken()
    // Avoid weird bug with token set in the future...
    await new Promise(res => setTimeout(res, 2000))
    onAuthComplete(authResult.user)
    setLoading(false)
  }
  const startSignIn = async () => {
    const ui = await getFirebaseUi()
    if (firebaseApp && ui) {
      ui.start("#firebaseui-auth-container", {
        signInOptions: AUTH_PROVIDERS,
        signInFlow: "popup",
        signInSuccessUrl: "/",
        callbacks: {
          signInSuccessWithAuthResult: (authResult, _redirectUrl) => {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            setIsNewUser(authResult.additionalUserInfo.isNewUser)
            handleAuthComplete(authResult)
            return false
          },
          uiShown: function () {
            // The widget is rendered.
            // Hide the loader.
          },
        },
        tosUrl: "/about/terms-of-service",
        privacyPolicyUrl: "/about/privacy-policy",
      })
    }
  }

  const getCopy = () => {
    if (loading && isNewUser) {
      return `Creating account, please wait...`
    } else if (loading && !isNewUser) {
      return `Signing in, please wait... `
    }

    return `Please create an account or sign in to proceed. We require an account to ensure no one abuses this website.`
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={onAuthAbort}
        aria-labelledby="auth-dialog-title"
      >
        <DialogTitle id="auth-dialog-title">Register</DialogTitle>
        <Box paddingX={3} paddingY={2}>
          <DialogContentText>{getCopy()}</DialogContentText>
        </Box>
        <AuthDialogContent onReady={startSignIn} />
        <Box display="flex" p={2} justifyContent="center">
          {loading && <CircularProgress />}
        </Box>
        <DialogActions>
          <Button disabled={loading} onClick={onAuthAbort} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const AuthDialogContent: React.FC<{ onReady: () => void }> = ({ onReady }) => {
  React.useEffect(() => {
    onReady()
  }, [])

  return (
    <Box paddingX={2} paddingY={1}>
      <div id="firebaseui-auth-container"></div>
    </Box>
  )
}
