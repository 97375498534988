import "firebaseui/dist/firebaseui.css"
import { isNotSSR } from "../utils"

import { firebaseApp } from "./firebase/firebase"

export const getFirebaseUi = async () => {
  if (isNotSSR() && firebaseApp) {
    const firebaseui = await import("firebaseui")
    return new firebaseui.auth.AuthUI(firebaseApp.auth())
  }
  return
}
