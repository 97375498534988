import { gql } from "@apollo/client"

export const listingItemCardFragment = gql`
  fragment ListingItemCardFragment on listings {
    id
    product_image_url
    price
    title
    url
    store
    volume_cl
    last_available_at
    deals {
      price_change_percent
      price_change_per_cl
    }
  }
`
