import React from "react"
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Typography,
  Divider,
  Slider,
} from "@material-ui/core"
import CreateIcon from "@material-ui/icons/Create"
import { clamp, round } from "lodash"
import { Rating } from "@material-ui/lab"
import { gql, useMutation } from "@apollo/client"

import {
  SubmitReviewMutation,
  SubmitReviewMutationVariables,
} from "src/generated/graphql"
import { AuthDialog } from "./AuthDialog"
import { firebaseApp } from "../service/firebase/firebase"
import {
  getMeasurablesForCategory,
  MEASURABLE_LABELS,
  Measureable,
  ProductCategory,
} from "../measurables"

const RATING_DESCRIPTIONS = [
  "Dog Shit",
  "Terrible",
  "Very Bad",
  "Bad",
  "Hmm...",
  "OK",
  "Good",
  "Very Good",
  "Amazing",
  "Jesus",
]

const ratingDescription = (rating: number) => {
  const index = clamp(round(rating * 2 - 1), 0, RATING_DESCRIPTIONS.length)
  return RATING_DESCRIPTIONS[index]
}

export interface ReviewDialogProps {
  productId: string
  category: ProductCategory
  initialRating?: number
  initalDescription?: string
  initialMeasurables?: Record<Measureable, number>
  onReviewSubmitted?: () => void
  openButton?: React.ReactElement<{ onClick: () => void }>
}

type MeasureableRecord = { [m in Measureable]?: number }

export const ReviewDialog: React.FC<ReviewDialogProps> = ({
  productId,
  onReviewSubmitted,
  openButton,
  initialRating,
  initalDescription,
  initialMeasurables,
  category,
}) => {
  const [open, setOpen] = React.useState(false)
  const [authOpen, setAuthOpen] = React.useState(false)
  const [rating, setRating] = React.useState<number>(initialRating ?? 2.5)
  const [description, setDescription] = React.useState<string>(
    initalDescription ?? ""
  )
  const [measurables, setMeasurables] = React.useState<MeasureableRecord>(
    initialMeasurables ?? {}
  )

  const [submitReview] =
    useMutation<SubmitReviewMutation, SubmitReviewMutationVariables>(
      submitRatingMutation
    )

  const handleClickOpen = () => {
    if (initialRating && initialRating !== rating) setRating(initialRating)
    if (initalDescription && initalDescription !== description)
      setDescription(initalDescription)
    if (initialMeasurables && initialMeasurables !== measurables)
      setMeasurables(initialMeasurables)
    setOpen(true)
  }

  const closeAndClear = () => {
    setOpen(false)
    setDescription("")
    setRating(2.5)
    setMeasurables({})
    onReviewSubmitted?.()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmitReview = async () => {
    if (firebaseApp?.auth().currentUser) {
      await submitReview({
        variables: {
          productId,
          rating,
          description: description?.length ? description : undefined,
          measurables,
        },
      })
      closeAndClear()
    } else {
      setOpen(false)
      setAuthOpen(true)
    }
  }

  const handleAuthComplete = async () => {
    await submitReview({
      variables: {
        productId,
        rating,
        description: description?.length ? description : undefined,
        measurables,
      },
    })

    setAuthOpen(false)
    closeAndClear()
  }

  const handleAuthAbort = () => {
    setAuthOpen(false)
    setOpen(true)
  }

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value)
  }

  const OpenButton = openButton ? (
    React.cloneElement(openButton, { onClick: handleClickOpen })
  ) : (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleClickOpen}
      startIcon={<CreateIcon />}
    >
      Add Review
    </Button>
  )

  return (
    <>
      {OpenButton}
      <AuthDialog
        open={authOpen}
        onAuthComplete={handleAuthComplete}
        onAuthAbort={handleAuthAbort}
      ></AuthDialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <Box
              marginY={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="h6" component="h3">
                What do you think?
              </Typography>
              <Box marginY={3} color="primary.main">
                <Typography variant="h2" component="h4">
                  {ratingDescription(rating)}
                </Typography>
              </Box>
              <Rating
                name="rating-input"
                value={rating}
                precision={0.5}
                size="large"
                onChange={(_event, newValue: number) => {
                  if (newValue) setRating(newValue)
                }}
              />
            </Box>
            <Box marginY={2}>
              <Divider />
            </Box>
            <Typography variant="h6" component="h3">
              Profile
            </Typography>
            <MeasurableInputs
              category={category}
              onChange={setMeasurables}
              initalValues={initialMeasurables}
            ></MeasurableInputs>
            <Box marginY={2}>
              <Divider />
            </Box>
            <Box marginY={2}>
              <Typography variant="h6" component="h3">
                Description
              </Typography>
              <Typography variant="caption">
                Anything that stands out? What does it taste like? What does it
                smell like?
              </Typography>
            </Box>
            <TextField
              value={description}
              onChange={handleDescriptionChange}
              id="description-input"
              variant="outlined"
              multiline
              rows={6}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmitReview} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

interface MeasurableInputProps {
  category: ProductCategory
  initalValues?: Record<string, number>
  onChange: (newValues: Record<Measureable, number>) => void
}

const getInitalState = (
  category: string,
  initalValues?: Record<string, number>
): Record<Measureable, number> => {
  return getMeasurablesForCategory(category as ProductCategory).reduce(
    (obj, measurable) => ({
      ...obj,
      [measurable]: (initalValues && initalValues[measurable]) ?? 0,
    }),
    {}
  ) as Record<Measureable, number>
}

const MeasurableInputs: React.FC<MeasurableInputProps> = ({
  category,
  initalValues,
  onChange,
}) => {
  const [values, setValues] = React.useState(
    getInitalState(category, initalValues)
  )

  const allMeasurables = getMeasurablesForCategory(category)

  const handleChange = (measurable: Measureable, value: number) => {
    setValues({ ...values, [measurable]: value })
  }

  const handleChangeCommitted = (measurable: Measureable, value: number) => {
    const newValues = { ...values, [measurable]: value }
    setValues(newValues)
    onChange(newValues)
  }

  return (
    <>
      {allMeasurables.map((measurable: Measureable) => {
        const labels = MEASURABLE_LABELS[measurable]

        return (
          <Box mt={1} key={measurable}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="caption">{labels[0]}</Typography>
              <Typography variant="caption">{labels[1]}</Typography>
            </Box>
            <Slider
              min={0}
              max={100}
              track={false}
              value={values[measurable]}
              onChangeCommitted={(_ev: any, newValue: number) =>
                handleChangeCommitted(measurable, newValue)
              }
              onChange={(_ev: any, newValue: number) =>
                handleChange(measurable, newValue)
              }
              aria-labelledby={measurable}
              // marks={marks}
            />
          </Box>
        )
      })}
    </>
  )
}

const submitRatingMutation = gql`
  mutation SubmitReview(
    $productId: uuid!
    $rating: float8!
    $description: String
    $measurables: jsonb
  ) {
    insert_reviews_one(
      object: {
        product_id: $productId
        rating: $rating
        description: $description
        measurables: $measurables
      }
      on_conflict: {
        constraint: reviews_product_id_user_id_key
        update_columns: [description, rating, measurables]
      }
    ) {
      id
      product_id
      description
      measurables
    }
  }
`
