import React from "react"
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Hidden,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import clsx from "clsx"
import { round } from "lodash"

import { ListingItemCardFragment } from "../__generated__/ListingItemCardFragment"
import { useHelperStyles } from "../../styles/helpers"
import { getAffiliateUrl } from "../../utils"
import { Store } from "../../Store"
import { StoreLogo } from "../StoreLogo"
// @ts-ignore
import STOCK_IMAGE_PLACEHOLDER from "../../images/whisky-stock.jpg"
import IN_STOCK_ICON from "../../images/in-stock-icon.svg"
import OUT_OF_STOCK_ICON from "../../images/out-of-stock-icon.svg"
import { listingIsUnavailable } from "./listingIsUnavailable"
import { graphql } from "gatsby"

const useStyles = makeStyles(theme => ({
  content: {
    flex: "1 0 auto",
  },
  coverLargeScreen: {
    height: "100%",
    width: "100%",
    backgroundSize: "contain",
    backgroundPositionY: "center",
  },
  coverSmallScreen: {
    width: 144,
    height: 144,
    backgroundSize: "contain",
    backgroundPositionY: "center",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  title: {
    fontSize: 18,
  },
  titleBigCard: {
    minHeight: 48,
  },
  smallLinstingCardContent: {
    width: "100%",
  },
  unavailable: {
    /* Backdrop filter */
    "-webkit-filter": "grayscale(100%)",
    filter: "grayscale(100%)",
  },
}))

export interface ListingItemCardProps {
  listing: ListingItemCardFragment
}

export const ListingItemCard = ({ listing }: ListingItemCardProps) => {
  const classes = useStyles()
  const utilClasses = useHelperStyles()

  const title = listing.title
  const volume = listing.volume_cl
  const price = listing.price
  const store = listing.store as Store
  const url = listing.url
  const imgUrl = listing.product_image_url
  const [deal] = listing.deals

  const stockIcon = listingIsUnavailable(listing) ? (
    <Tooltip title="Out of stock" aria-label="Out of stock">
      <img src={OUT_OF_STOCK_ICON} alt="out of stock" />
    </Tooltip>
  ) : (
    <Tooltip title="In stock" aria-label="in stock">
      <img src={IN_STOCK_ICON} alt="in stock" />
    </Tooltip>
  )

  const savings = round(deal?.price_change_percent || 0, 2)
  return (
    <>
      <Box width="100%" position="relative" borderRadius="16px">
        <Card
          className={
            listingIsUnavailable(listing) ? classes.unavailable : undefined
          }
        >
          {deal && (
            <Box position="absolute" top="8px" left="8px" zIndex="1">
              <Chip color="primary" label={`${savings}%`} />
            </Box>
          )}

          <a
            href={getAffiliateUrl(url, store) || "/"}
            className={classes.link}
            target="_blank"
            rel="noopener"
          >
            <Box>
              <CardActionArea>
                <Box display="flex">
                  <Hidden smDown>
                    <CardMedia
                      image={imgUrl || STOCK_IMAGE_PLACEHOLDER}
                      title={title}
                      className={classes.coverSmallScreen}
                    />
                  </Hidden>
                  <Box display="flex" width="100%">
                    <CardContent className={classes.smallLinstingCardContent}>
                      <Box
                        width={100}
                        height={40}
                        ml={{ xs: deal && "80px", md: 0 }}
                      >
                        <StoreLogo store={store} />
                      </Box>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h3"
                        className={clsx(classes.title, utilClasses.maxTwoLines)}
                      >
                        {title}
                      </Typography>
                      <Typography variant="body1" component="p">
                        {`${volume}cl`}
                      </Typography>
                    </CardContent>
                    <Box pl={2} pt={2} pb={2}>
                      <Box
                        mt={1}
                        display="flex"
                        flexDirection="column"
                        minWidth={96}
                        height="100%"
                        justifyContent="center"
                      >
                        <Box>
                          <Typography variant="body1" component="p">
                            <Box
                              component="span"
                              fontWeight="bold"
                              fontSize={20}
                            >
                              {`£${price}`}
                            </Box>
                          </Typography>
                          <Typography variant="body1" component="p">
                            <Box
                              component="span"
                              color="text.secondary"
                              fontSize={13}
                            >{`£${round(
                              (price / volume) * 70,
                              2
                            )} / 70cl`}</Box>
                          </Typography>
                        </Box>

                        <Box pt={2}>
                          <Box>{stockIcon}</Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <ChevronRightIcon style={{ fontSize: 32 }} />
                    </Box>
                  </Box>
                </Box>
              </CardActionArea>
            </Box>
          </a>
        </Card>
      </Box>
    </>
  )
}

export const staticListingItemCardFragment = graphql`
  fragment ListingItemCardFragment on Hasura_listings {
    id
    product_image_url
    price
    title
    url
    store
    volume_cl
    last_available_at
    deals {
      price_change_percent
      price_change_per_cl
    }
  }
`
